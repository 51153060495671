import React from "react";
import _ from 'lodash';

import "./tags.css"
import { Link } from "gatsby";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as icons from '@fortawesome/free-solid-svg-icons'

const TechTag = (props) => {
  const { tag, tech, icon, size, color, slug } = props

  let path = "";

  const tagsIndex = slug.indexOf('/tags/');
  let relevantBit = slug;
  if (tagsIndex > -1) {
    relevantBit = slug.substr(0, tagsIndex);
  }
  if (relevantBit.indexOf('category') > -1) {
    path += relevantBit;
  }

  path += `/tags/${_.kebabCase(tag)}`;

  return (
    <div className="d-inline-block p-1">
      <Link to={path}>
        <div
          className="tech-tag"
          style={{
            borderColor: color,
            fontSize: size,
          }}
        >
          <p className="d-inline">{tech}</p>
          <div className="d-inline" style={{ color: color }}>
            {icon.split(' ').map((i, index) => (
              <FontAwesomeIcon key={index} icon={icons[i]} size="sm" style={{ fontSize: '0.875em', height: '1em' }} />
            ))}
          </div>
        </div>
      </Link>
    </div>
  );
}

export default TechTag