import React from "react"
import _ from 'lodash';
import { StaticQuery, graphql, Link } from "gatsby"
import Bio from "./Bio"
import "./sidebar.css"

import SocialLinks from "./SocialLinks"
import TechTags from "./TechTags"


const Sidebar = ({ slug, category }) => {
  return (
    <StaticQuery
      query={graphql`
                query SiteBioQuery {
                    site {
                        siteMetadata {
                            title
                            tagline
                            author
                            contacts {
                                linkedin
                                github
                            }
                            labels {
                                tag
                                tech
                                group
                                icon
                                size
                                color
                            }
                        }
                    }
                    allMarkdownRemark(
                        limit: 2000
                        sort: { fields: [frontmatter___date], order: DESC }
                    ) {
                        edges {
                            node {
                                frontmatter {
                                    tags
                                    category
                                }
                            }
                        }
                    }
                }
            `}
      render={data => {
        const categoriesMap = data.allMarkdownRemark.edges
          .map((s) => s.node.frontmatter.category)
          .filter(_.identity)
          .reduce((acc, cat) => {
            _.set(acc, cat, {});
            return acc;
          }, {});

        return (
          <>
            <div className="sidebar-main border-right">
              <Bio author={data.site.siteMetadata.author} tagline={data.site.siteMetadata.tagline} />
              <SocialLinks contacts={data.site.siteMetadata.contacts} />
              <div className="page-links">
                <Link to="/"><span className="text-dark d-block py-1">Recent Posts</span></Link>
                {_.map(categoriesMap, (sub, cat) => {
                  return (
                    <Link key={cat} to={`/category/${_.kebabCase(cat)}`}><span className="text-dark d-block py-1">{cat}</span></Link>
                  );
                })}
                {/* <Link to="/about"><span className="text-dark d-block py-1">About</span></Link> */}
                {/* <Link to="/archive"><span className="text-dark d-block py-1">Archive</span></Link> */}
              </div>
              {slug !== '/' ? (
                <div className="tech-tags mt-4">
                  <TechTags labels={data.site.siteMetadata.labels} posts={data.allMarkdownRemark.edges.filter(p => p.node.frontmatter.category === category)} slug={slug} />
                </div>
              ) : null}
            </div>
          </>
        );
      }}
    />
  )
}

export default Sidebar