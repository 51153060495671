import React from "react"
import _ from "lodash"

import TechTag from "../tags/TechTag"

const TechTags = (props) => {
  const labels = props.labels
  const posts = props.posts

  const tags = labels.map(label => {
    let count = 0;
    posts.forEach(post => {
      if (post.node.frontmatter.tags.includes(label.tag)) {
        count = count + 1
      }
    })
    return [label.tag, count]
  }).filter(label => {
    return label[1] > 0
  }).map(category => {
    return category[0]
  });

  const getTechTags = (tags) => {
    const grouped = _.groupBy(tags, t => {
      const label = _.find(labels, l => l.tag === t);
      return label.group;
    });

    return _.mapValues(grouped, tags => tags.map((tag, i) => {
      const label = _.find(labels, l => l.tag === tag);
      return (
        <TechTag key={i} tag={label.tag} tech={label.tech} icon={label.icon} size={label.size} color={label.color} slug={props.slug} />
      );
    }));
  }

  const tagGroups = getTechTags(tags)
  return (
    <>
      <h4>Post Tags</h4>
      <div className="d-block">
        {_.map(tagGroups, (tags, group) => (
          <React.Fragment key={group}>
            <h5>{group}</h5>
            {tags}
          </React.Fragment>
        ))}
      </div>
    </>
  )
}


export default TechTags